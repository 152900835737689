:root {
  --preloader-circle-endsize: 20vw;
  --amazing-cubic:  cubic-bezier(.2,.9,.45,1);
}

body {
  background-color: white;
  transition: background-color .76s var(--amazing-cubic);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.preloader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  pointer-events: none;
}
.preloader-circle {
  width: var(--preloader-circle-endsize);
  height: 20vw;
  position: absolute;
  z-index: 500;
  top: calc(50vh - 0.5 * var(--preloader-circle-endsize));
  left: calc(50vw - 0.5 * var(--preloader-circle-endsize));
  background-color: rgb(7, 8, 19);
  transform: scale(500);
  border-radius: 100%;
}

.preloader-circle1 {
  animation: exitTransform 1.5s var(--amazing-cubic) forwards 1 .2s;
}

.preloader-circle2 {
  animation: exitTransform 1.5s var(--amazing-cubic) forwards 1 .3s;
  background-color: rgb(233, 74, 0);
  opacity: 1;
  z-index: 499;
}

@keyframes exitTransform {
  0% {
    transform: scale(500);
    opacity: 1;
  } 80% {
    opacity: 1;
  } 100% {
    transform: scale(0);
    opacity: 0;
  }
}
#darkmode-switch,
#custom-switch, #custom2-switch {
  pointer-events: all;
}

.darkmode-container,
.custom-container, .custom2-container {
  position: fixed;
  height: 30px;
  width: 100px;
  left: 3vw;
  z-index: 100;
  opacity: 0;
  transition: opacity 1s var(--amazing-cubic) 2s, bottom 1s var(--amazing-cubic) 2s;
  pointer-events: all;
}

.darkmode-container {
  bottom: 3vh;
}

.custom-container, .custom2-container {
  bottom: -3vh;
  opacity: 0;
  pointer-events: none;
}

.custom2-container {
  bottom: -6vh;
}

.darkmode-content,
.custom-content, .custom2-content {
  display: flex;
  flex-direction: column;
  width: 70%;
}

label,
.darkmode-toggle,
.custom-toggle, .custom2-toggle { 
  height: 30px;
  border-radius: 100px;
}

label {
  width: 100px;
  background-color: rgba(134, 134, 134, 0.158);
  border-radius: 100px;
  position: relative;
}

.settings-wheel:hover {
  cursor: none;
}

.darkmode-toggle,
.custom-toggle, .custom2-toggle {
  position: absolute;
  width: 50%;
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.322);
  transition: transform .3s var(--amazing-cubic);
}

.darkmode-label-sun,
.custom-label-sun, .custom2-label-sun { 
  position: absolute;
  width: 24px;
  left: 13px;
  top: 3px;
}

.darkmode-label-moon,
.custom-label-moon, .custom2-label-moon { 
  position: absolute;
  width: 20px;
  left: 65px;
  top: 5px;
  transition: filter var(--amazing-cubic) .5s;
  filter: invert(1);
}

.darkmode-label-dark,
.custom-label-dark, .custom2-label-dark {
  opacity: .5;
}

[type="checkbox"] {
  display: none;
}

[type="checkbox"]:checked + .darkmode-container .darkmode-toggle, /* Changed class names */
[type="checkbox"]:checked + .custom-container .custom-toggle { /* Changed class names */
  transform: translateX(100%);
  background-color: #000111;
}
[type="checkbox"]:checked + .custom2-container .custom2-toggle { /* Changed class names */
  transform: translateX(100%);
  background-color: #000111;
}

.settings, .bg-container, .title {
  opacity: 0;
  transition: opacity 1s var(--amazing-cubic) 2s;
}

.settings-wheel {
  position: fixed;
  height: 24px;
  top: 3vh;
  right: 3vw;
  opacity: 1;
  transition: transform .73s var(--amazing-cubic);
}

.settings-wheel-fill {
  opacity: 0;
  filter: invert(1);
  transition: opacity .7s var(--amazing-cubic);
  pointer-events: none;
}
.settings-bg {
  background-color: rgba(221, 201, 255, 0.493);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 100vw;
  top: 0;
  transition: left .73s cubic-bezier(.84,.35,0,1.01);
}
.bg {
  width: 80vw;
  height: 80vw;
  position: absolute;
  top: 0;
  left: calc(50vw - 0.5 * 80vw);
  transform: rotate(0deg);
  z-index: 1;
  transition: transform cubic-bezier(.71,.19,.43,.96), top .4s ease-in-out;
}
.settings-content {
  position: absolute;
  display: flex;
  width: calc(4 * 80px + 10vw);
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  z-index: 5;
  left: calc(50vw - 0.5 * (4 * 80px + 10vw));
  top: 0vh;
  opacity: 0;
  pointer-events: none;
  transition: top 1s cubic-bezier(.71,.19,.43,.96), opacity 1s cubic-bezier(.71,.19,.43,.96);
}

.option {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #00BD00 0%, rgba(0, 0, 0, 0.507) 100%);
  font-family: "Slackey", sans-serif;
  font-size: 18px;
  color: white;
  z-index: 6;
  padding-left: 5px;
  padding-right: 5px;
  opacity: .6;
  transform: translateY(-200px);
  transition: opacity .73s var(--amazing-cubic), transform .73s var(--amazing-cubic);
  border-radius: 5%;

}
.option-medium {
  font-size: 13px;
  background: linear-gradient(to bottom, #e5ff00 0%, rgba(0, 0, 0, 0.507) 100%);
  transition: opacity .73s var(--amazing-cubic), transform .73s var(--amazing-cubic) .1s;
}
.option-hard {
  font-size: 11px;
  padding-left: 4px;
  padding-right: 6px;
  background: linear-gradient(to bottom, #FF5B15 0%,rgba(0, 0, 0, 0.507) 100%);
  transition: opacity .73s var(--amazing-cubic), transform .73s var(--amazing-cubic) .2s;
}
.option-help {
  font-size: 14px;
  padding-left: 10px;
  background: linear-gradient(to bottom, #cf0000 0%, rgba(0, 0, 0, 0.507) 100%);
  transition: opacity .73s var(--amazing-cubic), transform .73s var(--amazing-cubic) .3s;
}

.option-selected {
  opacity: 1;
}
.option:hover {
  opacity: 1;
}

.settings-content p {
  font-family: "Slackey", sans-serif;
  color: rgb(255, 255, 255);
  font-size: 10vw;
  position: absolute;
  transform: translate(-50%, -50%);
  margin-top: 170px;
}

.title {
  font-family: "Nosifer", sans-serif;
  text-align: center;
  font-size: 10vw;
  position: relative;
  top: calc(5vw + 20px);
  color: black;
  z-index: 2;
}
.anime-container {
  font-family: "Slackey", sans-serif;
  color: rgb(255, 255, 255);
  font-size: 10vw;
  width: 54vw;
  left: calc(50vw - 0.5 * 54vw);
  position: absolute;
  opacity: 1;
  z-index: 4;
  top: 100px;
  opacity: 0;
  transition: top 1s cubic-bezier(.71,.19,.43,.96), opacity .5s cubic-bezier(.71,.19,.43,.96);
  pointer-events: none;
}

.anime-container .letter {
  display: inline-block;
}
.main {
  position: relative;
  z-index: 2;
  opacity: 1;
  transition: opacity .6s linear;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
}

.word, .title-jump {
  display: inline-block;
  pointer-events: all;
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.8s;
}
.title-jump {
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0 63%);
}

.title-jump:nth-child(1) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.8s;
}

.title-jump:nth-child(2) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 2.15s;
}

.title-jump:nth-child(3) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.46s;
}

.title-jump:nth-child(4) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.84s;
}

.title-jump:nth-child(5) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.69s;
}

.title-jump:nth-child(6) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 2.38s;
}

.word-bottom .title-jump:nth-child(1) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.92s;
}

.word-bottom .title-jump:nth-child(2) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 2.07s;
}

.word-bottom .title-jump:nth-child(3) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.61s;
}

.word-bottom .title-jump:nth-child(4) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.23s;
}

.word-bottom .title-jump:nth-child(5) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.9s;
}

.word-bottom .title-jump:nth-child(6) {
  transition: transform .73s var(--amazing-cubic), clip-path 2.5s var(--amazing-cubic) 1.92s;
}


@keyframes jumpItem {
  0% {
    transform: translateY(0px);
  } 33% {
    transform: translateY(-10px);
  } 66% {
    transform: translateY(5px);
  } 100% {
    transform: translateY(0px);
  }
}

.start-button {
  width: 75px;
  align-items: center;
  pointer-events: all;
  opacity: 0;
  transition: opacity .73s var(--amazing-cubic), transform .73s var(--amazing-cubic);
}

.start-button:hover {
  opacity: .4;
  transform: scale(1.2);
}

.start-container {
  position: absolute;
  left: calc(50vw - 0.5 * 75px);
  margin-top: 10vh;
}

.orb {
  width: 30px;
  position: absolute;
  transform: scale(1);
  transition: transform 4s ease-in-out;
  opacity: 0;
  pointer-events: all;
  z-index: 10;
}

.orb-hold-container {
  display: none;
}

.scoreboard {
  position: fixed;
  top: 3vh;
  left: 3vw;
  font-family: "Just Another Hand", sans-serif;
  font-size: 20px;
}
.scoreboard p {
  line-height: 0px;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: opacity .73s var(--amazing-cubic) 1s;
}

.scoreboard p:nth-child(6) {
  transition: opacity .73s var(--amazing-cubic) 1.1s;
}

.scoreboard p:nth-child(8) {
  transition: opacity .73s var(--amazing-cubic) 1.2s;
}

.scoreboard p:nth-child(10) {
  transition: opacity .73s var(--amazing-cubic) 1.3s;
}

.circle-selector {
  width: 40px;
  height: calc(5 * 40px + 8vh);
  position: fixed;
  right: 3vw;
  top: calc(50vh - 0.5 * (5*40px + 8vh));
  pointer-events: all;
  transform: translateX(10vw);
  transition: transform 1s var(--amazing-cubic) 1.2s, filter 1s var(--amazing-cubic) 1.2s;
  filter: opacity(0);
}

.selector-left {
  left: 3vw;
  transform: translateX(-10vw);
}

.circle-item {
  width: 40px;
  height: 40px;
  background-color: white;
  position: relative;
  margin-bottom: 2vh;
  border-radius: 100%;
  opacity: .4;
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic);
  transform: translateX(5vw);
}

.circle-item:nth-child(1) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .4s;
}
.circle-item:nth-child(2) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .3s;
}
.circle-item:nth-child(3) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .2s;
}
.circle-item:nth-child(4)  {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .1s;
}
.circle-item:nth-child(5)  {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic);
}

.circle-item-left {
  transform: translateX(-5vw);
}

.selector-left .circle-item:nth-child(1) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic);
}
.selector-left  .circle-item:nth-child(2) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .1s;
}
.selector-left  .circle-item:nth-child(3) {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .2s;
}
.selector-left  .circle-item:nth-child(4)  {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .3s;
}
.selector-left  .circle-item:nth-child(5)  {
  transition: opacity .73s var(--amazing-cubic), transform 1s var(--amazing-cubic) .4s;
}


.circle-item:hover {
  opacity: 1;
}

.circle-item-active {
  opacity: 1;
}

.custom-cursor {
  position: fixed;
  top: 10vh;
  left: 10vw;
  width: 24px;
  height: 24px;
  opacity: 0;
  pointer-events: none;
  z-index: 10000;
  transition: transform 300ms ease-in-out;
}
.custom-cursor:nth-child(3), .custom-cursor:nth-child(4) {
  transform: translate(-50%, -50%);
}


.cursor-active {
  opacity: 1;
}
.empty-cursor {
  opacity: 0 !important;
}
.circle-item-cursor {
  width: 24px;
  position: relative;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  pointer-events: none;
}
.circle-item-ball {
  position: relative;
  top: calc(50% - 11px);
  pointer-events: none;
}
.small-ball {
  position: relative;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.game-indicator {
  width: calc(1vw + 20px);
  height: calc(60vh - 85px);
  border: 1px solid black;
  border-radius: 25px;
  position: absolute;
  left: -15vw;
  background: linear-gradient(to bottom, #ffffff00 49%, #00000033 50%, #ffffff00 51%);
  top: calc(50vh - 0.5 * (60vh - 85px));
  transition: opacity .73s var(--amazing-cubic) 1s, left .73s var(--amazing-cubic) 1s, border .3s var(--amazing-cubic);

  display: none;

}
.indicator-seperator {
  width: calc(1vw + 20px);
  height: 1px;
  background-color: black;
  top: calc(50% - 0.5px);
  left: 0;
  position: absolute;
  transition: background-color .3s var(--amazing-cubic);

  display: none;
}
.evade-ball {
  position: absolute;
  transition: top .05s var(--amazing-cubic), left .05s var(--amazing-cubic);
  width: 50px;
  height: 50px;
  left: 30vw;
  top: 60vh;
  animation: rotateBall 6s forwards infinite;
  display: none;
  z-index: 200;
  pointer-events: all;
}
@keyframes rotateBall {
  0% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(360deg);
  }
}
.evade-ball img {
  width: 50px;
  height: 50px;
}
.game-indicator p {
  font-family: "Just Another Hand", sans-serif;
  font-size: 20px;
  color: black;
  position: absolute;
  left: 0;
}
.game-indicator p:nth-child(2){
  top: calc(-2vh - 33px);
}
.game-indicator p:nth-child(3){
  bottom: calc(-2vh - 35px);
}
.message-container {
  width: 120px;
  position: absolute;
  left: calc(50vw - 0.5 * 120px);
  top: calc(-20vh - 120px);
  z-index: 10000;
  transition: top .73s var(--amazing-cubic);
}
.message-container img {
  width: 120px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity .73s var(--amazing-cubic);
}
.message-container p {
  font-family: "Slacky", sans-serif;
  font-size: 35px;
  position: relative;
  color: white;
  top: 16px;
  text-align: center;
}
.made-by-github {
  width: 25px;
  height: 25px;
  position: fixed;
  z-index: 200;
  opacity: 1;
  bottom: 3vh;
  right: 3vw;
  opacity: 0;
  transition: opacity .73s var(--amazing-cubic) 2s;
}
.made-by-github img {
  width: 25px;
  height: 25px;
}